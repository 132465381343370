<template>
  <div class="SearchResult">
    <ul>
      <li>搜索结果：{{ value }}</li>
      <search-list
        v-for="item in result"
        :key="item.id"
        :item="item"
        :value="value"
        @play="$emit('play',$event)"
      ></search-list>
    </ul>
  </div>
</template>
<script>
import SearchList from "@/components/Search/SearchList";
export default {
  name: "SearchResult",
  props: {
    value: String,
  },
  data() {
    return {
      result: [],
    };
  },
  components: {
    SearchList,
  },
  created() {
    console.log(this.value);
    this.axios.get("/search?keywords=" + this.value).then((d) => {
      console.log(d);
      this.result = d.data.result.songs.map((data) => {
        data.song = {
          artists: data.artists,
          album: data.album,
        };
        return data;
      });
    });
  },
};
</script>
<style lang="less" scoped>
.SearchResult {
  ul {
    margin-top: 10px;
    li {
      &:nth-of-type(1) {
        background-color: #fafafa;
        height: 40px;
        line-height: 40px;
        text-indent: 1em;
        font-size: 14px;
        color: #282846;
        border-radius: 50px 50px 0 0;
        border-top: 3px dashed #d8ebe4;
        border-bottom: 1px solid #d8ebe4;
      }
    }
  }
}
</style>