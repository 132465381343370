<template>
  <div>
    <!-- 歌曲 -->
    <li class="songs">
      <!-- <i></i> -->
      <div class="songlist" @click.stop="$emit('play', item)">
        <p v-html="$options.filters.filt(item.name, value)"></p>
        <p>
          <span
            v-for="artist in item.song.artists"
            :key="artist.id"
            v-html="$options.filters.filt(artist.name, value)"
          >
          </span>
          -
          <span
            v-html="$options.filters.filt(item.song.album.name, value)"
          ></span>
        </p>
      </div>
      <i class="iconfont icon-play" @click.stop="$emit('play', item)"></i>
    </li>
  </div>
</template>
<script>
export default {
  name: "SearchList",
  props: {
    item: Object,
    value: {
      type: String,
      default: "",
    },
  },
  created() {
    // console.log(this.item)
    console.log(this.value);
  },
  filters: {
    filt(value, a) {
      return value.replace(a, '<span style="color:#007580;">' + a + "</span>");
    },
  },
};
</script>
<style lang="less" scoped>
li {
  display: flex;
  height: 55px;
  border-bottom: 2px dashed #d8ebe4;
  font-size: 15px;
  padding: 0 10px;
  i {
    width: 2%;
    display: inline-block;
    width: 55px;
    height: 55px;
    line-height: 55px;
    text-align: center;
    color: gray;
    font-size: 25px;
    padding: 0 0 0 10px;
  }
  .songlist {
    width: 98%;
    padding-top: 8px;
    align-items: center;
    p {
      font-size: 16px;
      color: #282846;
      overflow: hidden;
      text-overflow: ellipsis;
      text-overflow: -o-ellipsis-lastline;
      display: -webkit-box;
      -webkit-line-clamp: 1; //行数需设置
      -webkit-box-orient: vertical;
    }
    p:nth-of-type(2) {
      font-size: 13px;
      color: gray;
    }
  }
}
</style>