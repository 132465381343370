<template>
  <div class="tips">
    <ul class="list">
      <li>搜索提示：{{ value }}</li>
      <!-- 歌手 -->
      <li class="artists" v-for="ar in artists" :key="ar.id">
        <i class="iconfont icon-search"></i>
        <div class="songlist" @click.stop="$emit('search', { value: ar.name })">
          <img :src="ar.img1v1Url" />
          <p>【歌手】{{ ar.name }}</p>
        </div>
        <i class="iconfont icon-play"></i>
      </li>
      <!-- 歌单 -->
      <li class="playlists" v-for="pl in playlists" :key="pl.id">
        <i class="iconfont icon-search"></i>
        <div class="songlist" @click.stop="$emit('search', { value: pl.name })">
          <img :src="pl.coverImgUrl" />
          <p>【歌单】{{ pl.name }} - {{ pl.description }}</p>
        </div>
        <i class="iconfont icon-play"></i>
      </li>
      <!-- 专辑 -->
      <li class="albums" v-for="al in albums" :key="al.id">
        <i class="iconfont icon-search"></i>
        <div class="songlist" @click.stop="$emit('search', { value: al.name })">
          <img :src="al.artist.picUrl" />
          <p>【专辑】{{ al.name }} - {{ al.artist.name }}</p>
        </div>
        <i class="iconfont icon-play"></i>
      </li>
      <!-- 歌曲 -->
      <li class="songs" v-for="i in songs" :key="i.id">
        <i class="iconfont icon-search"></i>
        <div class="songlist" @click.stop="$emit('search', { value: i.name })">
          <p>{{ i.name }}</p>
          <p>
            <span v-for="(k, index) in i.artists" :key="index">
              {{ k.name }}
            </span>
            - {{ i.name }}
          </p>
        </div>
        <i class="iconfont icon-play"></i>
      </li>
      <li
        class="isShow"
        v-show="
          songs.length <= 0 &&
          albums.length <= 0 &&
          playlists.length <= 0 &&
          artists.length <= 0
        "
      >
        暂无数据
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "SearchTips",
  props: {
    value: String,
  },
  data() {
    return {
      songs: [],
      albums: [],
      playlists: [],
      artists: [],
      isShow: null,
    };
  },
  created() {
    this.tips(this.value);
    console.log(this.value);
  },
  watch: {
    value(newValue) {
      this.tips(newValue);
      console.log(newValue);
    },
  },
  methods: {
    tips(value) {
      this.axios.get("/search/suggest?keywords=" + value).then((d) => {
        if (
          d.data.result &&
          (d.data.result.songs ||
            d.data.result.albums ||
            d.data.result.playlists ||
            d.data.result.artists)
        ) {
          this.songs = d.data.result.songs;
          this.albums = d.data.result.albums;
          this.playlists = d.data.result.playlists;
          this.artists = d.data.result.artists;
          //   console.log(this.songs);
        }
        console.log(d);
      });
    },
  },
};
</script>
<style lang="less" scoped>
.tips {
  .list {
    margin-top: 10px;
    li {
      display: flex;
      height: 50px;
      border-bottom: 2px dashed #d8ebe4;
      font-size: 15px;
      &:nth-of-type(1) {
        background-color: #fafafa;
        height: 40px;
        line-height: 40px;
        text-indent: 1em;
        font-size: 14px;
        color: #282846;
        border-radius: 50px 50px 0 0;
        border-top: 3px dashed #d8ebe4;
        border-bottom: 1px solid #d8ebe4;
      }
      i {
        width: 1%;
        display: inline-block;
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-style: normal;
        color: gray;
        font-size: 22px;
        &:nth-of-type(2) {
          font-size: 25px;
          padding: 0 10px;
        }
      }
      .songlist {
        width: 98%;
        line-height: 22.5px;
        // display: flex;
        align-items: center;
        p {
          color: #282846;
          overflow: hidden;
          text-overflow: ellipsis;
          text-overflow: -o-ellipsis-lastline;
          display: -webkit-box;
          -webkit-line-clamp: 1; //行数需设置
          -webkit-box-orient: vertical;
        }
        p:nth-of-type(2) {
          font-size: 13px;
          color: gray;
        }
      }
    }
    .artists,
    .playlists,
    .albums {
      .songlist {
        display: flex;
        align-items: center;
        img {
          width: 45px;
          margin: 0 10px 0 0;
        }
      }
    }
  }
}
</style>