<template>
  <!-- <div class="search animate__animated animate__zoomInDown"> -->
  <div class="search animate__animated animate__fadeInUp">
    <input
      class=""
      type="text"
      placeholder="搜索音乐 / 专辑 / 歌手 / 歌单 / 用户"
      @input="inputing()"
      @keydown.enter="search(value)"
      v-model="value"
    />
    <component
      :is="componentId"
      @search="search"
      :value="value"
      @play="$emit('play', $event)"
    ></component>
  </div>
</template>
<script>
import SearchHot from "@/components/Search/SearchHot";
import SearchTips from "@/components/Search/SearchTips";
import SearchResult from "@/components/Search/SearchResult";
export default {
  name: "Search",
  data() {
    return {
      value: "",
      componentId: SearchHot,
    };
  },
  methods: {
    inputing() {
      console.log("111111111");
      if (this.value == "" || this.value.length <= 0) {
        this.componentId = SearchHot;
      } else {
        this.componentId = SearchTips;
      }
    },
    search(event) {
      if (event.value) {
        this.value = event.value;
      }
      this.componentId = SearchResult;
    },
  },
};
</script>
<style lang="less" scoped>
.search {
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  input {
    width: 100%;
    font-size: 15px;
    display: block;
    height: 40px;
    outline: none;
    text-indent: 1.5em;
    border: 2px solid #fed049;
    border-radius: 10px;
  }
}
</style>
