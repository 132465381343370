<template>
  <div class="hots">
    <h1>热门搜索：</h1>
    <ul class="list">
      <li
        v-for="(item, index) in hots"
        :key="index"
        @click.stop="$emit('search', { value: item.first })"
      >
        {{ item.first }}
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "SearchHot",
  props: {
    value: String,
  },
  data() {
    return {
      hots: [],
    };
  },
  created() {
    this.axios.get("/search/hot").then((d) => {
      this.hots = d.data.result.hots;
      //   console.log(d);
    });
  },
};
</script>
<style lang="less" scoped>
.hots {
  margin: 10px 0 10px 10px;
  color: #282846;
  h1 {
    font-size: 14px;
  }
  .list {
    overflow: hidden;
    li {
      float: left;
      font-size: 13px;
      margin: 5px;
      padding: 5px 10px;
      border: 2px solid #fed049;
      border-radius: 10px;
    }
  }
}
</style>